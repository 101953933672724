import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root from './components/features/screens/MainRoot';
import ErrorScreen from './components/features/screens/ErrorScreen';
import InventoryOverview from './components/features/screens/InventoryOverview';
import { NO_ACCOUNT_ID, useGlobalUserStore } from './state/userState';
import { Login } from './components/features/screens/Login';
import Logout from './components/features/screens/Logout';
import { useGlobalItemStore } from './state/itemsState';
import useRemoteItems from './service/remote/useRemoteItems';
import Home from './components/features/screens/Home';
import DisplayModeSelection, { displayModeSelectionLoader } from './components/features/screens/DisplayModeSelection';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorScreen />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "inventory",
                element: <InventoryOverview />,
            },
            {
                path: "display-mode-select",
                element: <DisplayModeSelection />,
                loader: displayModeSelectionLoader
            },
            {
                path: "logout",
                element: <Logout />,
            },
        ],
    },{
        path: "/login",
        element: <Login />,
    }
]);

function App() {

    const [fetchUserFromStorage, accountId] = useGlobalUserStore((state) => ([state.fetchFromStorage, state.id]));
    const setItems = useGlobalItemStore(state => state.setItems);

    const { getAllItems } = useRemoteItems();

    //get user from local storage
    useEffect(() => {
        fetchUserFromStorage()
            .then(
                user => {
                    console.log(`Login user ${user.id}:${user.email}`);
                }
            )
            .catch(
                error => {
                    console.error("Could not fetch user");
                }
            )
    }, []/*run once on mounted*/);

    //get user items
    useEffect(()=>{
        if (accountId !== NO_ACCOUNT_ID) {
            console.log("Top level item fetch");
            getAllItems()
                .then(
                    items => setItems(items)
                );
        }
    }, [accountId]);

    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}

export default App;
