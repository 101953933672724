import { create } from "zustand";

export type AppLayout = "normal" | "content-only";

interface GlobalAppLayoutState {
    appLayout: AppLayout,
    setAppLayout: (newLayout:AppLayout) => void,
}

export const useGlobalAppLayoutStore = create<GlobalAppLayoutState>()((set) => ({
    appLayout: "normal",
    setAppLayout: (newLayout:AppLayout) => set(
        () => ({ appLayout: newLayout})
    )
}))