import { useEffect } from "react"
import { useGlobalUserStore } from "../../../state/userState";
import { useNavigate, useNavigation } from "react-router-dom";

export default function Logout(){

    const navigate = useNavigate();
    const logOut = useGlobalUserStore((store) => store.logOut);

    useEffect(()=>{
        logOut();
        navigate("/login");
    }, []);

    return <h1>
        You are being logged out...
    </h1>
}