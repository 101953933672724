import { jwtDecode } from "jwt-decode";
import { Authentication } from "../model/remote/Authentication";

export default class DataService {

    static async get(url: string, params?: { [key: string]: string }, headers?: { [key: string]: string }) {
        const urlWithParams = params ? `${url}?${DataService.objectToQueryString(params)}` : url;
        return fetch(urlWithParams, {
            method: "GET",
            headers: {
                ...headers
            }
        })
            .then(
                response => {
                    if (!response.ok) {
                        console.trace();
                        throw new Error('HTTP response status was not 2xx:' + response.status);
                    }
                    return response;
                }
            );
    }

    static async post(url: string, body: string, headers?: { [key: string]: string }) {
        return fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            body: body
        })
            .then(
                response => {
                    if (!response.ok) {
                        console.trace();
                        throw new Error('HTTP response status was not 2xx:' + response.status);
                    }
                    return response
                }
            );
    }

    static async delete(url: string, params?: { [key: string]: string }, headers?: { [key: string]: string }) {
        const urlWithParams = params ? `${url}&${DataService.objectToQueryString(params)}` : url;
        return fetch(urlWithParams, {
            method: "DELETE",
            headers: {
                ...headers
            }
        })
            .then(
                response => {
                    if (!response.ok) {
                        console.trace();
                        throw new Error('HTTP response status was not 2xx:' + response.status);
                    }
                    return response;
                }
            );
    }

    static async patch(url: string, body: string, params?: { [key: string]: string }, headers?: { [key: string]: string }) {
        const urlWithParams = params ? `${url}&${DataService.objectToQueryString(params)}` : url;
        return fetch(urlWithParams, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            body: body
        })
            .then(
                response => {
                    if (!response.ok) {
                        console.trace();
                        throw new Error('HTTP response status was not 2xx:' + response.status);
                    }
                    return response;
                }
            );
    }

    static jwtIsExpired(token: string) {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
            if(!decoded.exp) throw new Error("exp in jwt is undefined");
            return decoded.exp < currentTime;
        } catch (error) {
            console.error("Failed to decode JWT:", error);
            return true; // Assume expired if there's an error decoding the token
        }
    }

    static objectToQueryString(obj: { [key: string]: string }) {
        const keyValuePairs = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) { // Check if key is directly on the object
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key]);
                keyValuePairs.push(`${encodedKey}=${encodedValue}`);
            }
        }
        return keyValuePairs.join('&');
    }

    static authHeaders(tokens: Authentication) {
        return {
            accept: "*/*",
            Authorization: `Bearer ${tokens.access_token}`
        }
    }

    static refreshHeaders(tokens: Authentication) {
        return {
            accept: "*/*",
            Authorization: `Bearer ${tokens.refresh_token}`
        }
    }

}