import React, { useEffect, useState } from "react"
import { useGlobalItemStore } from "../../../state/itemsState"
import useRemoteScanEvents from "../../../service/remote/useRemoteScanEvents"
import TimeHelper from "../../../service/TimeHelper"
import { Item } from "../../../model/remote/Item"
import { sharedStyles } from "../../../styles/sharedStyles"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../lib/shadcn/ui/card"
import { Badge } from "../../../lib/shadcn/ui/badge"
import { Package } from "lucide-react"

type ScanEventResponse = Array<{
    localDeviceId: string,
    scanEvents: Array<{
        id: number,
        creationTimestamp: string,
        epc: string,
        rssi: number
    }>
}>

export default function InventoryOverview() {
    const globalItems = useGlobalItemStore(store => store.items);
    const { getScans } = useRemoteScanEvents();
    const { fetchAllMostRecentItemScans } = useRemoteScanEvents();
    const [mostRecentTimestamps, setMostRecentTimestamps] = useState<Array<{
        itemId: number,
        timestamp: Date
    }>>([]);
    const [mostRecentFetch, setMostRecentFetch] = useState(0);

    const fetchInterval = 8000;
    const availableCutoff = 18000;

    /**
     * Initialize items
     */
    useEffect(() => {
        setMostRecentTimestamps(globalItems.map(item => ({
            itemId: item.id,
            timestamp: new Date(0)
        })));
    }, [globalItems]);

    useEffect(() => {
        const fetchAllItemScans = () => {
            fetchAllMostRecentItemScans(fetchInterval * 2).then(
                recentScans => {
                    setMostRecentTimestamps(oldTimestamps => oldTimestamps.map(
                        oldItem => {
                            oldItem.timestamp = recentScans.filter(item => item.itemId === oldItem.itemId)[0]?.timestamp || new Date(0);
                            return oldItem;
                        }
                    ))
                }
            ).finally(() => {
                setMostRecentFetch(Date.now())
            })
        }
        fetchAllItemScans();
        console.log("Start Inverval");
        let interval = setInterval(() => {
            fetchAllItemScans();
        }, fetchInterval)
        return () => {
            clearInterval(interval);
        }
    }, [globalItems])

    const itemTimestamp = (item: Item) => {
        return mostRecentTimestamps.filter((filterItem) => filterItem.itemId === item.id)[0]?.timestamp.getTime() || 0;
    }
    const itemsWithinTolerance = () => {
        return globalItems.filter(item => {
            return Date.now() - itemTimestamp(item) < availableCutoff
        })
    }
    /**
     * checks whether the most recent fetch is within a
     * specific timeframe to check if the data is up to date
    */
    const mostRecentFetchValid = () => {
        return mostRecentFetch + availableCutoff > Date.now();
    }

    const availableItems = itemsWithinTolerance();

    const renderItem = ({ item, index, available }: {
        item: Item,
        index: number,
        available: boolean
    }) => {

        const timeD = Date.now() - itemTimestamp(item);
        return <Card key={index} style={{...styles.itemCard, ...(available?{background: "radial-gradient(circle at top left, #00ff080d 120px, #00ff1024)"}:{}) }}>
            <CardHeader style={styles.cardHeader} className="flex justify-between">
                <div style={{display:"flex", alignItems:"center"}}>
                    <Package size={32} />
                    <div style={{marginLeft:20}}>
                        <CardTitle>{item.itemName}</CardTitle>
                        <CardDescription>{item.epc}</CardDescription>
                    </div>
                </div>
                {
                    available && <Badge style={{ margin: 0 }} variant={"default"}>verfügbar</Badge>
                }
                {
                    !available && <Badge style={{ margin: 0 }} variant={"secondary"}>nicht verfügbar</Badge>
                }
            </CardHeader>
        </Card>
    }

    return <div style={{ ...styles.wrapper, ...sharedStyles.pageWrapper }}>
        <h1 style={styles.headline} className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
            Inventar
        </h1>
        <div style={styles.cards}>
            {
                globalItems.length === 0 &&
                <div style={styles.noItems}>No items</div>
            }
            {
                globalItems.length !== 0 && !mostRecentFetchValid() && <div>Loading...</div>
            }
            {
                mostRecentFetchValid() && availableItems.length !== 0 &&
                <div style={styles.itemContainer}>
                    <div>
                        {
                            availableItems.map((item, index) => renderItem({ item, index, available: true }))
                        }
                    </div>
                </div>
            }
            {
                mostRecentFetchValid() && availableItems.length < globalItems.length &&
                <div style={styles.itemContainer}>
                    <div>
                        {
                            globalItems
                                .filter(item => availableItems.indexOf(item) === -1)
                                .map((item, index) => renderItem({ item, index, available: false }))
                        }
                    </div>
                </div>
            }
        </div>
    </div>
}

const styles: any = {
    wrapper: {
        flexGrow: 1,
        padding: 20
    },
    headline: {
        marginBottom: 10,
    },
    cardHeader: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center"
    },
    cards: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    itemContainer: {
        flex: 1,
        margin: 10
    },
    itemCard:{
        margin:10
    }
}