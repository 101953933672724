import { Authentication } from "../../model/remote/Authentication";
import DataService from "../DataService";
import {API_URL} from "../../env"

/**
 * This class is a collection of functions for user management. It is not a hook,
 * because it should work without any application context.
 */
export default class RemoteUserService {

    static async authenticate(email: string, password: string):Promise<Authentication> {
        return DataService.post(API_URL + "/auth/authenticate", JSON.stringify({
            email: email,
            password: password
        }))
        .then(
            resp => resp.json()
        )
        .then(
            json => {
                return json;
            }
        )
    }

    static refreshToken(auth:Authentication) {
        console.log("Refresh token using:", auth.refresh_token);
        return DataService.post(API_URL + "/auth/refresh-token", JSON.stringify({}), DataService.refreshHeaders(auth));
    }

    static async register(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        accountName: string,
    ):Promise<Authentication> {
        return DataService.post(API_URL + "/auth/register", JSON.stringify({
            email: email,
            password: password,
            firstname: firstName,
            lastname: lastName,
            accountname: accountName,
            apiKey: "" // KEY MISSING
        }))
            .then(
                resp => resp.json()
            )
            .then(
                json => {
                    console.log({
                        access_token:json.access_token,
                        refresh_token:json.refresh_token
                    });
                    return {
                        access_token:json.access_token,
                        refresh_token:json.refresh_token
                    }
                }
            )
    }

    static async get(auth:Authentication) {
        return DataService.get(API_URL + "/users", undefined, DataService.authHeaders(auth))
            .then(
                resp => resp.json()
            )
    }
}