import { Link, NavLink } from "react-router-dom"
import logo from "../../assets/images/doora_logo.png"
import { Boxes, House, LogOut, Package, TvMinimal } from "lucide-react"
import { useGlobalAppLayoutStore } from "../../state/appLayoutState"

export default function Navbar() {

    const appLayout = useGlobalAppLayoutStore(state => state.appLayout);

    if(appLayout === "content-only"){
        return <></>
    }
    //@ts-ignore
    return <div style={styles.navWrapper}>
        <div style={styles.headerContainer}>
            <img style={styles.logo} src={logo} />
            <h1 style={styles.header}>DOORA</h1>
        </div>
        <div style={styles.menuList}>
            <NavLink style={styles.navItem} to="/" className={({ isActive, isPending }) =>isActive?"activeNavItem":isPending?"pendingNavItem":""}>
                <House />
                <span style={styles.navItemText}>Home</span>
            </NavLink>
            <NavLink style={styles.navItem} to="/inventory" className={({ isActive, isPending }) =>isActive?"activeNavItem":isPending?"pendingNavItem":""}>
                <Boxes strokeWidth={1.6} />
                <span style={styles.navItemText}>Inventar</span>
            </NavLink>
            <NavLink style={styles.navItem} to="/display-mode-select" className={({ isActive, isPending }) =>isActive?"activeNavItem":isPending?"pendingNavItem":""}>
                <TvMinimal strokeWidth={1.6} />
                <span style={styles.navItemText}>Display Mode</span>
            </NavLink>
            <NavLink style={styles.navItem} to="/logout" className={({ isActive, isPending }) =>isActive?"activeNavItem":isPending?"pendingNavItem":""}>
                <LogOut />
                <span style={styles.navItemText}>Logout</span>
            </NavLink>
        </div>
    </div>
}

const styles: any = {
    navWrapper: {
        minWidth: "300px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        borderRightWidth: "1px",
        borderStyle: "solid",
        borderColor: "rgb(231, 231, 231)"
    },
    headerContainer: {
        display: "flex",
        padding: "20px 0",
        margin:20,
        alignItems: "center",
        justifyContent:"center",
        flexDirection: "column",
        borderBottom: "1px solid rgb(231, 231, 231)"
    },
    logo: {
        marginBottom:4,
        height: 64,
        width: 64
    },
    header: {
        fontSize: "1.8em",
        alignContent: "center"
    },
    menuList: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start"
    },
    navItem: {
        display:"flex",
        justifyContent:"flex-start",
        padding:12,
        marginLeft:12,
        marginRight:12
    },
    activeNavItem:{
        backgroundColor: "red"
    },
    navItemText: {
        marginLeft:16,
        fontSize:16,
        fontWeight:500
    }
}