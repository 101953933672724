import { StoreApi, UseBoundStore, create } from "zustand";
import { Authentication } from "../model/remote/Authentication";
import { User } from "../model/remote/User";
import DataService from "../service/DataService";
import UserService from "../service/remote/UserService";

/**
 * IMPORTANT: This interface needs to be shallow to allow for optimal
 * saving/loading
 */
export interface PersistentGlobalUserState extends User {
    lastSaved: string,
    access_token: string,
    refresh_token: string
}

export interface GlobalUserState extends PersistentGlobalUserState {
    fetchFromStorage: () => Promise<PersistentGlobalUserState>,
    saveToStorage: () => Promise<PersistentGlobalUserState>,
    logIn: (user:User, auth:Authentication) => void,
    refreshSession: () => Promise<void>,
    sessionExpired: () => boolean,
    requireValidSession: () => Promise<boolean>,
    logOut: () => Promise<void>
}

export const NO_ACCOUNT_ID = -1;

const initialState:PersistentGlobalUserState = {
    id:NO_ACCOUNT_ID,
    email:"",
    accountname:"",
    firstname:"",
    lastname:"",
    role:"",

    lastSaved: "",
    access_token: "",
    refresh_token: ""
}

export const useGlobalUserStore:UseBoundStore<StoreApi<GlobalUserState>> = create<GlobalUserState>()((set) => ({
    ...initialState,
    noAccount:false,
    fetchFromStorage: async () => {
        const rawUserState = await localStorage.getItem("userState");
        if(rawUserState == undefined) throw new Error("Could not read userState from storage");
        const parsedUserState:PersistentGlobalUserState = JSON.parse(rawUserState);
        set(() => ({
            ...parsedUserState
        }));
        return parsedUserState;
    },
    saveToStorage: async () => {
        const currentPersistenState:PersistentGlobalUserState =  {
            id: useGlobalUserStore.getState().id,
            email: useGlobalUserStore.getState().email,
            accountname: useGlobalUserStore.getState().accountname,
            firstname: useGlobalUserStore.getState().firstname,
            lastname: useGlobalUserStore.getState().lastname,
            role: useGlobalUserStore.getState().role,

            lastSaved: useGlobalUserStore.getState().lastSaved,
            access_token: useGlobalUserStore.getState().access_token,
            refresh_token: useGlobalUserStore.getState().refresh_token
        }
        await localStorage.setItem("userState", JSON.stringify(currentPersistenState));
        return currentPersistenState;
    },
    logIn: (user:User, auth:Authentication) => {
        set(() => ({
            ...user,
            ...auth
        }))
    },
    refreshSession: async () => {
        try{
            console.log("Refreshing session");
            const newAuth = await (await UserService.refreshToken({
                access_token:useGlobalUserStore.getState().access_token,
                refresh_token:useGlobalUserStore.getState().refresh_token
            })).json();

            set(() => ({
                access_token:newAuth.access_token,
                refresh_token:newAuth.refresh_token
            }));
            //no await so storage error are not caught here
            useGlobalUserStore.getState().saveToStorage();
        }
        catch(error){
            console.error("Session could not be refreshed:", error);
            throw new Error("Session could not be refreshed");
        }
    },
    sessionExpired: () => {
        return DataService.jwtIsExpired(useGlobalUserStore.getState().access_token);
    },
    /**
     * Refresh the session if necessary. If it cannot refresh, the user is
     * logged out.
     * @returns true if a valid session is given, else false
     */
    requireValidSession: async () => {
        try {
            if(useGlobalUserStore.getState().sessionExpired()){
                await useGlobalUserStore.getState().refreshSession();
            }
            return true;
        } catch (error) {
            console.error("Session error:", error);
            await useGlobalUserStore.getState().logOut();
            return false;   
        }
    },
    logOut: async () => {
        await localStorage.removeItem("userState");
        set(() => ({
            ...initialState,
            noAccount:false
        }))
    }
}))