import { Link, Outlet } from "react-router-dom";
import Navbar from "../Navbar";


export default function MainRoot() {
  return (
    <div style={styles.appWrapper}>
      <Navbar />
      <div style={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

const styles = {
  appWrapper:{
    display:"flex",
    height:"100vh",
    width:"100vw"
  },
  content:{
    display:"flex",
    flexGrow:1,
    overflow:"auto"
  }
}