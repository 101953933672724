import { create } from "zustand";
import { Item } from "../model/remote/Item";

interface GlobalItemState {
    items: Array<Item>,
    setItems: (items:Array<Item>) => void,
}

export const useGlobalItemStore = create<GlobalItemState>()((set) => ({
    items: [],
    setItems: (items:Array<Item>) => set(
        () => ({ items: items})
    )
}))