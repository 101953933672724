import { timeStamp } from "console";
import { API_URL, BACKEND_URL, LOCALHOST_URL } from "../../env";
import { useGlobalItemStore } from "../../state/itemsState";
import { GlobalUserState, useGlobalUserStore } from "../../state/userState";
import DataService from "../DataService";
import TimeHelper from "../TimeHelper";

export type UnknownScannedTag = {
    id: number,
    epc: string,
    rssi: number,
    lastSeen: string
}

const useRemoteScanEvents = () => {

    const globalRequireValidSession = useGlobalUserStore((state: GlobalUserState) => state.requireValidSession);
    const globalItems = useGlobalItemStore(store => store.items);


    const getScans = async (beforeTimestamp: string, itemId?: number, deviceId?: string) => {
        if (!(await globalRequireValidSession())) {
            throw new Error("getAllItems not executed due to session error");
        }
        const currentAuth = useGlobalUserStore.getState();
        let params: any = {
            beforeTimestamp
        }
        if (deviceId) params.deviceId = deviceId;
        if (itemId) params.itemId = itemId
        return DataService.get(API_URL + "/items/scan-events", params, DataService.authHeaders(currentAuth))
            .then(
                resp => resp.json()
            )
    }

    const getUnknownScans = async (deviceId: string): Promise<Array<UnknownScannedTag>> => {
        if (!(await globalRequireValidSession())) {
            throw new Error("getUnknownScans not executed due to session error");
        }
        const currentAuth = useGlobalUserStore.getState();
        let params: any = {
            deviceId
        }
        return DataService.get(API_URL + "/items/unknown-tags", params, DataService.authHeaders(currentAuth))
            .then(
                resp => resp.json()
            )
    }

    /**
     * Get all the most recent item scans for all items. This is useful
     * for real-time inventory overviews. Basically a big wrapper around getScans().
     * @param differenceMs max age of returned scan events in milliseconds 
     * @returns 
     */
    const fetchAllMostRecentItemScans = (differenceMs:number):Promise<Array<{itemId:number,timestamp:Date, deviceId:string}>> => {

        type ScanEventResponse = Array<{
            localDeviceId: string,
            scanEvents: Array<{
                id: number,
                creationTimestamp: string,
                itemId: number,
                rssi: number
            }>
        }>

        if (globalItems.length === 0) {
            return Promise.resolve([]);
        }
        return getScans((new Date(Date.now() - differenceMs)).toISOString())
            .then((resp: ScanEventResponse) => {
                let updatedItems = [];
                //for each local device
                for (let index in resp) {
                    const localDeviceReport = resp[index];
                    //for each item
                    if (localDeviceReport.scanEvents.length === 0) {
                        continue;
                    }
                    for (let globalItem of globalItems) {
                        //find the latest index with the correct epc
                        let latestIndex = 0;
                        while (localDeviceReport.scanEvents[latestIndex].itemId !== globalItem.id) {
                            //if the first index is reached with out match, break this iteration
                            if (latestIndex === localDeviceReport.scanEvents.length - 1) {
                                break;
                            }
                            latestIndex++;
                        }
                        //if the loop breaks on the last entry and it does not match the epc, continue with the other items
                        if (localDeviceReport.scanEvents[latestIndex].itemId !== globalItem.id) {
                            continue;
                        }
                        const latestScanEvent = localDeviceReport.scanEvents[latestIndex];
                        let timestamp;
                        //the local server does not run on central time can be parsed as is
                        //@ts-ignore
                        if(BACKEND_URL !== LOCALHOST_URL){
                            timestamp = TimeHelper.preventTimezoneConversion(latestScanEvent.creationTimestamp);
                        }else{
                            timestamp = new Date(latestScanEvent.creationTimestamp);
                        }
                        //add the latest timestamp
                        updatedItems.push({
                            itemId: globalItem.id,
                            timestamp: timestamp,
                            deviceId: localDeviceReport.localDeviceId
                        })
                    }
                }
                return updatedItems;
            })
    }

    return { getScans, getUnknownScans, fetchAllMostRecentItemScans }
}

export default useRemoteScanEvents;