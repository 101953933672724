import { API_URL } from "../../env";
import { GlobalUserState, useGlobalUserStore } from "../../state/userState";
import DataService from "../DataService";

const useRemoteItems = () => {

    const globalRequireValidSession = useGlobalUserStore((state:GlobalUserState) => state.requireValidSession);

    const getAllItems = async () => {
        if(!(await globalRequireValidSession())){
            throw new Error("getAllItems not executed due to session error");
        }
        //using the hook would only refresh the auth after each render which would be too slow
        const currentAuth = useGlobalUserStore.getState();
        return DataService.get(API_URL + "/items", undefined, DataService.authHeaders(currentAuth))
            .then(
                resp => resp.json()
            )
    }

    const createItem = async (epc:string, itemName:string, itemDescription:string, iconID:number) => {
        if(!(await globalRequireValidSession())){
            throw new Error("CreateItem not executed due to session error");
        }
        const currentAuth = useGlobalUserStore.getState();
        return DataService.post(API_URL + "/items", JSON.stringify({
            epc, 
            itemName,
            itemDescription,
            iconID
        }), DataService.authHeaders(currentAuth))
            .then(
                resp => {
                    return resp.json();
                }
            )
    }

    const updateItem = async (id:number, epc:string, itemName:string, itemDescription:string, iconID:number) => {
        if(!(await globalRequireValidSession())){
            throw new Error("updateItem not executed due to session error");
        }
        const currentAuth = useGlobalUserStore.getState();
        return DataService.patch(API_URL + "/items/" + id, JSON.stringify({
            epc, 
            itemName,
            itemDescription,
            iconID
        }), undefined,  DataService.authHeaders(currentAuth))
            .then(
                resp => resp.json()
            )
    }

    const deleteItem = async (id:number) => {
        if(!(await globalRequireValidSession())){
            throw new Error("deleteItem not executed due to session error");
        }
        const currentAuth = useGlobalUserStore.getState();
        return DataService.delete(API_URL + "/items/" + id, undefined, DataService.authHeaders(currentAuth));
    }

    return { getAllItems, createItem, updateItem, deleteItem}
}

export default useRemoteItems;