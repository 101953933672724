export default class TimeHelper {
    /**
     * If the passed date string does not end with a "Z" it is
     * appended to let the Date parser know that the passed time 
     * is already standard time format.
     * @param timestamp 
     * @returns 
     */
    static preventTimezoneConversion(timestamp:string): Date{
        return new Date(`${timestamp}${timestamp.charAt(timestamp.length - 1) !== "Z"?"Z":""}`);
    }
}